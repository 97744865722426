import React from "react";
import "./rslrCArd.scss";
import { GrStar } from "react-icons/gr";
import { API_URL } from "../../../utils/constants";
import { TbCurrencyTaka } from "react-icons/tb";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useWindowSize } from "../../../utils/custom-hooks";
export default function ResellerCard({ item, routename }) {
  // const percentage = Math.floor(Math.random() * (40 - 20 + 1)) + 20;

  let tempprice = item?.size_price?.length > 0 ? item?.size_price[0]?.price : item?.price ;
  const percentage = tempprice < 200 ? 20 : (tempprice < 300 ? 25 : (tempprice < 400 ? 30 :(tempprice < 500 ? 35 :(tempprice < 600 ? 38 : (tempprice < 700 ? 32 :28)) )))
  const calculatedPercentage = (percentage / 100) * tempprice;
  const lessprice = Math.floor(calculatedPercentage);


  var numbers = [3.9, 4.1,4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5];
  var randomIndex = Math.floor(Math.random() * numbers.length);
  // var rating = numbers[randomIndex];


  var rating = tempprice < 200 ? 4.2 : (tempprice < 300 ? 4.3 : (tempprice < 400 ? 4.4 :(tempprice < 500 ? 4.5 :(tempprice < 600 ? 4.6 : (tempprice < 700 ? 4.7 :4.8)) )))

  let lessprice2 = item?.size_price?.length > 0 ? item?.size_price[0]?.price : item?.price;
const windowsize = useWindowSize();
 

  return (
    <Link className="cards" to={`/${routename}/product/${item?._id}`}>
      <div className="discount">-{percentage}%</div>
      <img
        src={
          API_URL +
          "/" +
          item.images.find((item) => item.mainImage == true).image
        }
        alt=""
        height="200px"
      />
      <div className="card-details">
        <div className="product-sold-rating">
          {" "}
          <span className="product-sold">{percentage - 10} sold </span>
          <span className="product-rating">
            | <GrStar style={{ translate: "0px 2px", color: "grey" }} /> {rating}
          </span>
        </div>
        <div className="product-name">
          {item?.product?.title?.substring(0, `${windowsize?.width<350? 38 : (windowsize?.width<480 ? 34 : 42 )}`)}
        </div>
        <div className="product-cost">
          <span>
            {" "}
            <TbCurrencyTaka
              style={{ fontSize: "22px", translate: "0px 4px" }}
            />
            {item?.size_price?.length > 0 ? item?.size_price[0]?.price : item?.price }

          </span>
          <span className="price-less">
            <TbCurrencyTaka
              style={{ fontSize: "20px", translate: "0px 4px" }}
            />
            {lessprice+ lessprice2}
          </span>
        </div>
      </div>
      {/* <div className="buy-btn">Buy Now</div> */}
    </Link>
  );
}
