/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  message,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  MailTwoTone,
  MobileTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isNumberRegex } from "../../../../utils/helper.functions";
import BaseModal from "../../../common/base-modal";
import {
  setIsLoggedIn,
  setShowLoginModal,
  setShowRegisterModal,
  setUserData,
} from "../../../../utils/redux/actions/actions";
import CodeVerificationModal from "../code-verification";
import AccountCreated from "../account-created";
import RegistrationPending from "../registration-pending";
import ForgotPasswordModal from "../forgot-password";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";
import { useWindowSize } from "../../../../utils/custom-hooks";

import "./styles.scss";
import Spinner from "../../../common/spinner";
import { Notification } from "../../../../utils/helper.functions";


const Register = ({ showRegister, onClose }) => {
  const auth = new API.Auth();
  const country = new API.Country();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [fullname, setfullname] = useState("");
  const [number, setnumber] = useState("");
  const [password, setPassword] = useState("");

  const isProperNumberRegex = /(^(01){1}[3456789]{1}(\d){8})$/

  const history = useHistory();

  const size = useWindowSize();
  const handleemailchange = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  };

  const handlenumberChange = (e) => {
      setnumber(e.target.value);
  }


  const errorcheck = () => {
    if (fullname?.length == 0) {
      Notification("error", "Write your name");
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Notification("error", "Invalid Email");
      return true;
    }

    const mobileRegex = RegExp(/(^(01){1}[3456789]{1}(\d){8})$/);
    console.log(mobileRegex.test(number), number);
    if (!mobileRegex.test(number)) {
      Notification("error", "Invalid Number");
      return true;

    }

    if (password.length == 0) {
      Notification("error", "Write your password");
      return true;
    }
    return false;
  };

  const onFinish = async () => {
    const payload = {
      full_name: fullname,
      email: email,
      password: password,
      contact_number: number,
    };
    let flag = errorcheck();
    if (flag) {
      return;
    }
    await getDataManager(auth?.userregistration, setLoading, payload)
      .then((res) => {

        if (res?.code == 200) {
          Notification("success", res.message);
          onClose();
          dispatch(setShowLoginModal(true));
        } else {
          console.log({res});
          Notification("warning", res?.response?.data?.message);
      }
  })
      .catch((err) => {
        console.log("error===========", err);
        Notification("warning", err.message);

      });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      <BaseModal type2={true} showModal={showRegister} closeModal={onClose}>
        <section className="tajira-login-modal">
          <div className="login-modal-left-section">
            <Form form={form} layout="horizontal"
                      onFinish={onFinish}

            >
              <header className="header">Registration Form</header>

              <Form.Item
                name="Full Name"
                label="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your fullname",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter fullname"
                  visible={true}
                  onCopy={() => ""}
                  onChange={(e) => {
                    setfullname(e.target.value);
                  }}
                  visibilityToggle={false}
                  onPaste={() => ""}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"

                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  addonBefore={<MailTwoTone style={{ color: "black" }} />}
                  placeholder="Please enter email"
                  visible={true}
                  onCopy={() => ""}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  visibilityToggle={false}
                  onPaste={() => ""}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="username"
                rules={[
                  {
                    pattern: isProperNumberRegex,
                    message: "Please enter valid mobile numbers",
                  },
                  {
                    required: true, message: "Please enter phone number"
                  },
                ]}
              >
                <Input
                  addonBefore={<MobileTwoTone style={{ color: "black" }} />}
                  size="large"
                  placeholder="Please enter mobile number"
                  onChange={(e) => {
                    handlenumberChange(e);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined />}
                  placeholder="Please enter Password"
                  visible={true}
                  onCopy={() => ""}
                  visibilityToggle={false}
                  onPaste={() => ""}
                  size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                className="tajira-base-button outlined"
                  type="primary"
                  block
                  htmlType="submit"

                  style={{marginBottom:"15px"}}
                >
                  Register
                </Button>
                <Button
                  className="tajira-base-button outlined"
                  block
                  onClick={() => {
                    onClose();
                    dispatch(setShowLoginModal(true));
                  }}
                >
                  Click to login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </section>
      </BaseModal>
    </>
  );
};

export default Register;
