import React, { useEffect, useState } from "react";
import { GrStar } from "react-icons/gr";
import { Link } from "react-router-dom";
import { API_URL } from "../../../utils/constants";
import { TbCurrencyTaka } from "react-icons/tb";
import { Button, Card, Empty } from "antd";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../utils/custom-hooks";
import ResellerCard from "../../reseller/similarproduct/rslrCard";
import "./commonProduct.scss";

export default function CommonProduct({
  Similar_product_name = "Random Product",
  products,
  routename,
}) {
  const slider = React.useRef(null);
  const [sliderPrd, setSliderPrd] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { rsshopdata } = useSelector((state) => {
    return state?.app;
  });

  const routePath = location.pathname;
  const routepaths = routePath.split("/");
  const windowsize = useWindowSize();
  console.log(Similar_product_name, products);

  useEffect(() => {
    if (windowsize?.width < 860) {
      let tempproduct = products.slice(0, 4);
      setSliderPrd(tempproduct);
    } else {
      setSliderPrd(products);
    }
  }, [products, windowsize]);

  if (sliderPrd.length == 0) {
    return (
      <>
        <Card>
          <Empty
            description="No product found!"
            style={{ marginTop: "30vh" }}
          />
        </Card>
      </>
    );
  } else {
    return (
      <div style={{ marginBottom: "50px",}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="slider_title">{Similar_product_name}</h4>
          <div style={{ translate: "-23px 0px" }} className="slider-btn">
            <span
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "500",
                fontFamily: "monospace",
              }}
            >
              <button
                style={{
                  padding: "10px 10px 7px 10px",
                  background: "#397D86",
                  color: "white",
                  border: ".3px solid grey",
                  borderRadius: "4px",
                  fontWeight: 600,
                }}
              >
                <Link to={`/${routepaths[1]}/product`}>View All</Link>
              </button>
            </span>
          </div>
        </div>
        <div className="common-product">
          {sliderPrd &&
            sliderPrd?.length > 0 &&
            sliderPrd.map((item, index) => {
              // const percentage = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
              const percentage =
                item?.price < 200
                  ? 20
                  : item?.price < 300
                  ? 25
                  : item?.price < 400
                  ? 30
                  : item?.price < 500
                  ? 35
                  : item?.price < 600
                  ? 38
                  : item?.price < 700
                  ? 32
                  : 28;
              const calculatedPercentage = (percentage / 100) * item.price;
              const lessprice = Math.floor(calculatedPercentage);

              var numbers = [
                3.9,
                4.1,
                4.2,
                4.3,
                4.4,
                4.5,
                4.6,
                4.7,
                4.8,
                4.9,
                5,
              ];
              var randomIndex = Math.floor(Math.random() * numbers.length);
              // var rating = numbers[randomIndex];

              var rating =
                item?.price < 200
                  ? 4.2
                  : item?.price < 300
                  ? 4.3
                  : item?.price < 400
                  ? 4.4
                  : item?.price < 500
                  ? 4.5
                  : item?.price < 600
                  ? 4.6
                  : item?.price < 700
                  ? 4.7
                  : 4.8;

              return (
                <div className="common-single-card">
                  <ResellerCard item={item} key={index} routename={routename} />
                </div>
              );
            })}
        </div>
        <div className="bottom-btn-div">
        <button className="bottom-btn">
                <Link to={`/${routepaths[1]}/product`}>View All</Link>
              </button>
        </div>

      </div>
    );
  }
}
