import Country from "./Country";
import Products from "./Products";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import Order from "./Order";
import Promotion from "./Promotion";
import Home from "./Home";
import Wallet from "./Wallet";
import Coupon from "./Coupon";
import Address from "./Address";
import Category from "./Category";
import Mail from "./Mail";
import Auth from "./Auth";
import Profile from "./Profile";
import Checkout from "./Checkout";
import Bank from "./Bank";
import Pages from "./Pages";
import Brand from "./Brand";
import Banner from "./Banner";
import Slider from "./Slider";
import Tracking from "./Tracking";
import Subscriptions from "./Subscriptions";
import Messages from "./Messages";
import Upload from "./Upload";
import Wholesale from "./Wholesale";
import Reseler from "./Reseler";
import WholesalerDashboard from "./wholesellDashboard";
import Landing from "./landing";
import AdminDashboard from "./adminDashboard";

const API = {
  Country,
  Products,
  Cart,
  Wishlist,
  Order,
  Promotion,
  Home,
  Wallet,
  Coupon,
  Address,
  Category,
  Mail,
  Auth,
  Profile,
  Checkout,
  Bank,
  Pages,
  Brand,
  Banner,
  Slider,
  Tracking,
  Subscriptions,
  Messages,
  Upload,
  Reseler,
  Wholesale,
  WholesalerDashboard,
  Landing,
  AdminDashboard
};

export default API;
