// export const API_URL = "https://tajira.techtsy.com/";
export const API_URL = "https://api.shopyfastbd.com";

export const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-ws-token")) || {})
    .token;

export const Tokenfunc = () => {
      const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-ws-token")) || {})
        .token || (JSON.parse(localStorage.getItem("shopyfastbd-rs-token")) || {})
        .token;
      return `Bearer ${TOKEN}`;
    };

export const WSTokenfunc = () => {
  const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-ws-token")) || {})
    .token;
  return `Bearer ${TOKEN}`;
};

export const AMTokenfunc = () => {
  const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-am-token")) || {})
    .token;
  return `Bearer ${TOKEN}`;
};



export const CSTokenfunc = () => {
  const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-cs-token")) || {})
    .token;
  return `Bearer ${TOKEN}`;
};


export const RSTokenfunc = () => {
  const TOKEN = (JSON.parse(localStorage.getItem("shopyfastbd-rs-token")) || {})
    .token;
  return `Bearer ${TOKEN}`;
};

export const WSUserInfo = () => {
  const UserInfo = (JSON.parse(localStorage.getItem("shopyfastbd-ws-token")) || {})
    .user;
  return UserInfo;
};

export const RSUserInfo = () => {
  const UserInfo = (JSON.parse(localStorage.getItem("shopyfastbd-rs-token")) || {})
    .user;
  return UserInfo;
};

export const CSUserInfo = () => {
  const UserInfo = (JSON.parse(localStorage.getItem("shopyfastbd-cs-token")) || {})
    .user;
  return UserInfo;
};

export const AMUserInfo = () => {
  const UserInfo = (JSON.parse(localStorage.getItem("shopyfastbd-am-token")) || {})
    .data;
  return UserInfo;
};


export const RSbearerToken = RSTokenfunc();
export const WSbearerToken = WSTokenfunc();
export const CSbearerToken = CSTokenfunc();
export const AMbearerToken = AMTokenfunc();



export const RSbearerTokenLength = RSbearerToken.length;
export const WSbearerTokenLength = WSbearerToken.length;
export const CSbearerTokenLength = CSbearerToken.length;
export const AMbearerTokenLength = AMbearerToken.length;

